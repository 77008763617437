import {Route, Routes} from 'react-router-dom'
import Home from './pages/Home';
import Leaderboard from './pages/Leaderboard';
import Games from './pages/Games';
import MuiNavbar from './components/MuiNavbar'
import ReactDOM from 'react-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFontAwesome, faTwitch, faYoutube, faDiscord, faSteam} from '@fortawesome/free-brands-svg-icons'
import { faBars, faPaperPlane, faCopy } from '@fortawesome/free-solid-svg-icons'

library.add(faFontAwesome, faTwitch, faYoutube, faDiscord, faSteam, faBars, faPaperPlane, faCopy)

function App() {
  
  return (
    <>
      <MuiNavbar/>
      
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/Games" element={<Games/>} />
        <Route exact path="/Leaderboard" element={<Leaderboard/>} />
      </Routes>
    </>
  );
}

export default App;
