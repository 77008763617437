import React, { useState, useEffect} from "react";
import { invoke } from "../awsinvoke"
import CollapsibleTable from '../components/MuiTable'
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import HistoryIcon from '@mui/icons-material/History';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { Link } from "@mui/material";

export default function Leaderboard(){
    const [lastModified, setLastModified] = useState(null)
    const [stats, setStats] = useState([]);
    useEffect(() => {
        const lambdaN = "GetChatStats";
        invoke(lambdaN).then((value) => {
        //console.log("resolved====")
        //console.log(value)
        if (value.result) {
            //make table
            let parsed = JSON.parse(value.result);
            setLastModified(parsed[0]);
            setStats(parsed[1]);
        }
    });
    }, [setLastModified]);

    /*let rows=[
        {
        name: "testName",
        history: [
            {
                date: "1",
                tag: "moment1"
            }
        ]
    },
    {
        name: "testName2",
        history: [
            {
                date: "2",
                tag: "moment2"
            }
        ]
    }
    ]*/

    function compare(a,b){
        if (b.total < a.total)
            return -1;
        if (b.total > a.total)
            return 1;
        return 0;
    }

    if (stats != null){
        stats?.map((row) => {
            let total = 0;
            for (let key in row){
                if (!key.includes("Prog")){
                    if (!key.includes("time") && !key.includes("current") && !key.includes("name") && !key.includes("history") && !key.includes("rank")){
                        total += row[key];
                    }
                }
            }
            row.total = total;
        })
    
        stats?.sort(compare);
        stats?.forEach(function(row,ind){
            row.rank = ind + 1
        })
    }
    

    return (
    <div style={{padding: 20}}>
        <div style={{display: "flex", flexDirection: "row", marginBottom: 15, alignItems: "center", overflow:"hidden", textOverflow: "ellipsis"}}>
            {/*<img src="/logoW512.png" alt="logo" style={{width: 100, height: "auto"}}/>*/}
            <Typography variant="h1" sx={{fontSize: {xs: 24, md: 48}}} color="#9888C7" noWrap={true}>leaderboard</Typography>
        </div>
        
        <Paper style={{marginBottom:10, flexDirection: "row", display: "flex", justifyContent: "space-between"}} sx={{padding: 1}} elevation={6}>
                <div style={{flex: 1, paddingBottom: 10}}>
                <Divider textAlign="left" style={{marginBottom: 5}}>
                    <Chip style={{backgroundColor:"#272635"}} label={<Typography variant="h6" sx={{color:"#ffefa8"}}>What?</Typography>}/>
                </Divider>
                <Typography variant="caption">In stream chat, type any osrs skill preceded by an "!" to start leveling an idle bot. Ex. "!hunter"
                </Typography>
                </div>

                <Divider orientation="vertical" flexItem sx={{marginRight:1, marginLeft: 1}} />

                <div style={{flex: 1, paddingBottom: 10}}>
                <Divider textAlign="left" style={{marginBottom: 5}}>
                <Chip style={{backgroundColor:"#272635"}} label={<Typography variant="h6" sx={{color:"#fcc69d"}}>Where?</Typography>}/>
                </Divider>
                <Typography variant="caption">You can level your bot at <Link href="https://www.twitch.tv/devindoubt" target="_blank">devindoubt on Twitch</Link>
                </Typography>
                </div>
                
        </Paper>
        {stats == null || stats?.length == 0 ? <LinearProgress /> :
        <div>
            {stats != null && <CollapsibleTable rows={stats}/>}
            <Paper sx={{padding: 1,  alignItems: "center", flexDirection: "row", flex: 1, display: "flex", width: "max-content"}} elevation={6}>
                <HistoryIcon fontSize='small' color="primary"/>
                <Typography variant="caption" sx={{marginLeft:1, marginRight: 1}}>Last Update: {lastModified != null ? lastModified.substring(1, 11) : "..." }</Typography>
            </Paper>
        </div>
        }
    </div>
)}