import React from "react";
import {
    Box,
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Drawer
} from "@mui/material"
import {Link, useMatch, useResolvedPath} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const drawerWidth = 120;
export default function MuiNavbar(){
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setDrawerOpen((prevState) => !prevState);
    };

    //const container = window !== undefined ? () => window().document.body : undefined;

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center'}}>
            <Box display="flex" alignItems="center" justifyContent="space-between" px={2} style={{backgroundColor: "#9888C7", height: 64}}>
                <IconButton  target="_blank" onClick={() => window.open('https://store.steampowered.com/search/?developer=devindoubt')}>
                    <FontAwesomeIcon icon="fa-brands fa-steam" style={{color: "white",}} />
                </IconButton>
                <IconButton target="_blank" onClick={() => window.open('https://discord.gg/jRqtdjKUHf')}>
                    <FontAwesomeIcon icon="fa-brands fa-discord" style={{color: "white",}} />
                </IconButton>
                <IconButton target="_blank" onClick={() => window.open('https://twitch.tv/devindoubt')}>
                    <FontAwesomeIcon icon="fa-brands fa-twitch" style={{color: "white",}} />
                </IconButton>
                <IconButton target="_blank" onClick={() => window.open('https://www.youtube.com/@devindoubt')}>
                    <FontAwesomeIcon icon="fa-brands fa-youtube" style={{color: "white",}} />
                </IconButton>
            </Box>
            <Box display="flex" flexDirection="column" py={2}>
                <CustomLink to="/"><Typography variant="h5">contact</Typography></CustomLink>
                <CustomLink to="/games"><Typography variant="h5">games</Typography></CustomLink>
                {/*<CustomLink to="/leaderboard"><Typography variant="h5">devinchatbot</Typography></CustomLink>*/}
            </Box>
        </Box>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{height: 64, justifyContent: "center"}}>
                <Toolbar sx={{justifyContent: 'space-between', display: "flex"}}>
                    <div style={{width: 300}}>
                        <CustomLink to="/">
                            <Box display="flex" component="img" alt="devindoubt" src="/logoW512.png" sx={{maxHeight: 44}}/>
                        </CustomLink>
                    </div>
                    <Box sx={{ display: { xs: 'none', md: 'flex'} }} alignItems="center" justifySelf="center">
                        <CustomLink to="/">contact</CustomLink>
                        <CustomLink to="/games">games</CustomLink>
                        {/*<CustomLink to="/leaderboard">devinchatbot</CustomLink>*/}
                    </Box>
                    <div style={{width: 300}}>
                        <Box sx={{ display: { xs: 'none', md: 'flex'} }} justifyContent={"flex-end"}>
                            <IconButton target="_blank" onClick={() => window.open('https://store.steampowered.com/search/?developer=devindoubt')}>
                                <FontAwesomeIcon icon="fa-brands fa-steam" style={{color: "white",}} />
                            </IconButton>
                            <IconButton target="_blank" onClick={() => window.open('https://discord.gg/jRqtdjKUHf')}>
                                <FontAwesomeIcon icon="fa-brands fa-discord" style={{color: "white",}} />
                            </IconButton>
                            <IconButton target="_blank" onClick={() => window.open('https://twitch.tv/devindoubt')}>
                                <FontAwesomeIcon icon="fa-brands fa-twitch" style={{color: "white",}} />
                            </IconButton>
                            <IconButton target="_blank" onClick={() => window.open('https://www.youtube.com/@devindoubt')}>
                                <FontAwesomeIcon icon="fa-brands fa-youtube" style={{color: "white",}} />
                            </IconButton>
                        </Box>
                    </div>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { xs: 'inline-block', md: 'none'} }}
                    >
                        <FontAwesomeIcon icon="fa-solid fa-bars" style={{color: "#272635"}} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                //container={container}
                variant="temporary"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                anchor="right"
                PaperProps={{
                    sx: {
                        backgroundColor: "#272635"
                    }
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                >
                {drawer}
                </Drawer>
            </nav>
        </Box>
    )
}

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    
    return(
        <Link tabIndex="-1" className={isActive ? "active" : ""} to={to} {...props}>
            <Button color="inherit" >
                {children}
            </Button>
        </Link>
    )
}