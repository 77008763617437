import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
    palette: {
        primary: {
            main: "#9888C7"
        },
        secondary: {
            main: "#272635"
        },
        highlight: {
            main: "#ffefa8"
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        },
    },
});
theme = responsiveFontSizes(theme);

export default theme;