import React from 'react';
import {
    Box,
    Typography,
    Paper
} from "@mui/material"
import ButtonBanner from '../components/ButtonBanner'

export default function Home(){

    return (
        <Box sx={{marginTop: 2}}>
            <ButtonBanner/>
        </Box>
    )
}