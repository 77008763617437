import { InvokeCommand, LambdaClient, LogType, ServiceException } from "@aws-sdk/client-lambda";
window.Buffer = window.Buffer || require("buffer").Buffer;

//access k
//AKIA6AERGZA3XONPBTM6
//secret ak
//oc4AQc0a+fi0wbn2Nee+5wmq78r7Z/nEcBwT45uv
const invoke = async (funcName, payload) => {
    const client = new LambdaClient({
        credentials: {
            accessKeyId: "AKIA6AERGZA3XONPBTM6",
            secretAccessKey: "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"
        },
        region: 'us-east-2'
    });
    const command = new InvokeCommand({
        FunctionName: funcName,
        Payload: JSON.stringify(payload),
        LogType: LogType.Tail, 
    });

    const { Payload, LogResult } = await client.send(command);
    const result = Buffer.from(Payload).toString();
    const logs = Buffer.from(LogResult, "base64").toString();
    return { logs, result }; 
};

export { invoke };