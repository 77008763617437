import * as React from 'react';
//import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StarIcon from '@mui/icons-material/Star';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const tableStyles = {
    sticky: {
        position: 'sticky',
        left: 0,
        backgroundColor: "#eae6f5",
    },
    progressBar: {
        height: 5,
        backgroundColor: "#B1E5F2",
        borderRadius: 2,
    },
    progressBase: {
        height: 2,
        backgroundColor: "#fcc69d",
        borderRadius: 2,
        width: "100%",
    }
}
const colors = {
    gold: "#ffefa8",
    goldIcon: "#ccba6a",
    silver: "#f2f4f5",
    silverIcon: "#c0c1c2",
    bronze: "#fcc69d",
    bronzeIcon: "#cc946a"
}

const progCap = 600;
const historyChip = <Typography variant="button" component="div">
Moments History
</Typography>

function RankChip(rank, label, useIcon = false){
    let bgColor = colors.gold;
    let iconColor = colors.goldIcon
    switch(rank){
        case 2:
            bgColor = colors.silver;
            iconColor = colors.silverIcon;
            break;
        case 3:
            bgColor = colors.bronze;
            iconColor = colors.bronzeIcon;
            break;
    }

    let chip = useIcon ? <Chip icon={<StarIcon fontSize='small' style={{color:iconColor}}/>} style={{backgroundColor:bgColor}} label={label}/> :
    <Chip style={{backgroundColor:bgColor}} label={label}/>
    return chip;
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
        <TableRow >
            {/*<TableCell>
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
            >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            </TableCell>*/}
            <TableCell align="right"> {row.rank <= 3 ? RankChip(row.rank, row.rank, true) : row.rank }</TableCell>
            <TableCell component="th" scope="row" style={tableStyles.sticky}>
            {row.rank <= 3 ? RankChip(row.rank, row.name) : row.name}
            </TableCell>
            <TableCell sx={{flexDirection: "row", justifyContent:"flex-end", textAlign: "right"}} align="right">{row.rank <= 3 ? RankChip(row.rank, row.total) : row.total}</TableCell>
            <TableCell align="right">{row.agility}
                <div style={{ width: row.agilityProg ? (row.agilityProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.attack}
                <div style={{ width: row.attackProg ? (row.attackProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.construction}
                <div style={{ width: row.constructionProg ? (row.constructionProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.cooking}
                <div style={{ width: row.cookingProg ? (row.cookingProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.crafting}
                <div style={{ width: row.craftingProg ? (row.craftingProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.defence}
                <div style={{ width: row.defenceProg ? (row.defenceProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.farming}
                <div style={{ width: row.farmingProg ? (row.farmingProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.firemaking}
                <div style={{ width: row.firemakingProg ? (row.firemakingProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.fishing}
                <div style={{ width: row.fishingProg ? (row.fishingProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.fletching}
                <div style={{ width: row.fletchingProg ? (row.fletchingProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.herblore}
                <div style={{ width: row.herbloreProg ? (row.herbloreProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.hunter}
                <div style={{ width: row.hunterProg ? (row.hunterProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.magic}
                <div style={{ width: row.magicProg ? (row.magicProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.mining}
                <div style={{ width: row.miningProg ? (row.miningProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.prayer}
                <div style={{ width: row.prayerProg ? (row.prayerProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.ranged}
                <div style={{ width: row.rangedProg ? (row.rangedProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.runecraft}
                <div style={{ width: row.runecraftProg ? (row.runecraftProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.slayer}
                <div style={{ width: row.slayerProg ? (row.slayerProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.smithing}
                <div style={{ width: row.smithingProg ? (row.smithingProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.strength}
                <div style={{ width: row.strengthProg ? (row.strengthProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.thieving}
                <div style={{ width: row.thievingProg ? (row.thievingProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>
            <TableCell align="right">{row.woodcutting}
                <div style={{ width: row.woodcuttingProg ? (row.woodcuttingProg / progCap) * 100 + "%" : "0%", ...tableStyles.progressBar}}/>
                <div style={tableStyles.progressBase}/></TableCell>

        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: "none" }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Paper sx={{ margin: 1, padding: 1 }} elevation={2}>
                <Divider textAlign="left">
                <Chip icon={<AutoAwesomeIcon fontSize='small' color="primary"/>} label={historyChip} style={{backgroundColor:colors.silver}}></Chip>
                </Divider>
                <Table size="small">
                    <TableHead>
                    <TableRow>
                        <TableCell align="left">Date</TableCell>
                        <TableCell>Tag</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {row?.history?.map((historyRow) => (
                        <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row" align="left">
                            {historyRow.date}
                        </TableCell>
                        <TableCell>{historyRow.tag}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </Paper>
            </Collapse>
            </TableCell>
        </TableRow>
        </React.Fragment>
    );
}


export default function CollapsibleTable({ rows }) {
    return (
        <TableContainer style={{marginBottom: 10}} component={Paper} elevation={6}>
            <Table size="small" aria-label="collapsible table">
                <TableHead sx={{backgroundColor: colors.bronze}}>
                    <TableRow>
                        {/*<TableCell /> //moments dropdown */}
                        <TableCell align="right">Rank</TableCell>
                        <TableCell style={tableStyles.sticky}>Name</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="right">Agility</TableCell>
                        <TableCell align="right">Attack</TableCell>
                        <TableCell align="right">Construction</TableCell>
                        <TableCell align="right">Cooking</TableCell>
                        <TableCell align="right">Crafting</TableCell>
                        <TableCell align="right">Defence</TableCell>
                        <TableCell align="right">Farming</TableCell>
                        <TableCell align="right">Firemaking</TableCell>
                        <TableCell align="right">Fishing</TableCell>
                        <TableCell align="right">Fletching</TableCell>
                        <TableCell align="right">Herblore</TableCell>
                        <TableCell align="right">Hunter</TableCell>
                        <TableCell align="right">Magic</TableCell>
                        <TableCell align="right">Mining</TableCell>
                        <TableCell align="right">Prayer</TableCell>
                        <TableCell align="right">Ranged</TableCell>
                        <TableCell align="right">Runecraft</TableCell>
                        <TableCell align="right">Slayer</TableCell>
                        <TableCell align="right">Smithing</TableCell>
                        <TableCell align="right">Strength</TableCell>
                        <TableCell align="right">Thieving</TableCell>
                        <TableCell align="right">Woodcutting</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <Row key={row.name} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}