import React, { useState, useEffect} from "react";
import {
    Box,
    Typography,
    Button,
    IconButton,
    Drawer,
    Paper,
    LinearProgress
} from "@mui/material"
import ButtonBanner from '../components/ButtonBanner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default function Games(){
    const blitzDesc = "Pinball Blitz combines arcade pinball with a fast-paced action roguelike. Shape each run with selectable themes, flipper setups, and table upgrades to defeat bosses and achieve high-scores."

    return (
        <div>
            <div style={{padding: 20, marginBottom: 200}}>
                <Typography sx={{fontSize: {xs: 24, md: 48}}} variant="h1" color="#9888C7" style={{marginBottom: 15}}>games</Typography>
                
                <Box sx={{marginTop: 10, display: "flex", paddingLeft: {xs: null, md: null, lg: 10}, paddingRight: {xs: null, md: null, lg: 10}, flexDirection: {xs: "column", md: "column", lg: "row"}, justifyContent: "center", alignItems: "center"}}>
                    {/*<Paper sx={{display: { xs: 'none', md: 'block'}, height: 20, width: "60%", position: "absolute", borderRadius: 50, background: '#9888C7'}} elevation={6}/>*/}
                    <Paper sx={{padding: 1, marginRight: 4, marginBottom: 4, backgroundColor: "#9888C7"}} elevation={6}>
                        <Box display="flex" component="img" alt="devindoubt" src="/blitzHoriz.png" sx={{maxWidth: {xs: 250, sm: 500, md: 600}}}/>
                    </Paper>
                    <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <Typography sx={{fontSize: {xs: 24, md: 32}}} variant="h2" color="#9888C7">{blitzDesc}</Typography>
                        <Button sx={{marginTop: 4, paddingLeft: 5, paddingRight: 5, height: 40, display: "flex", justifyContent:"center", alignItems: "center", borderRadius: 50}} variant='contained' color='highlight' target="_blank" onClick={() => window.open('https://store.steampowered.com/app/2828800/Pinball_Blitz/')}>
                            <Typography sx={{marginRight: 1, color: "#272635"}} variant="subtitle2">view on steam</Typography>
                            <FontAwesomeIcon icon="fa-brands fa-steam" style={{color: "#272635"}} size="2x"/>
                        </Button>
                    </Box>
                </Box>
            </div>
            <ButtonBanner/>
        </div>
    )
}